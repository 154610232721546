export const Project = {
  RevenueCat: {
    apple: 'appl_',
    google: 'goog_',
  },
  api: 'https://api.hoxtonmix.com/api/admin/',
  apiAuth: 'https://api.hoxtonmix.com/api/v3/',
  chargebeeUrl: 'https://hoxtonmix.chargebee.com',
  codepush: {
    android: {
      production: '52_yx7psCRNIW2GePo3LK9dS7pJX7SUUnATWe',
      staging: 'G06ZyOr1R7HaoZ8r-l5BQ9KVFjmdcfPI0N-c9',
    },
    ios: {
      production: 'PgXR9f6H-fZipoZ8Gis9cItBqNlyzazIAAJj5',
      staging: 'Ww7zWeD8eVjMFXb6dcexd2oUWKpr4EBBYHUwH',
    },
  },
  cognito: {
    oauth: {
      domain: 'ssg-boilerplate.auth.eu-west-2.amazoncognito.com',
      // optional, for Cognito hosted ui specified options
      options: {
        // Indicates if the data collection is enabled to support Cognito advanced security features. By default, this flag is set to true.
        // AdvancedSecurityDataCollectionFlag: true
      },

      redirectSignIn: 'http://localhost:3000',

      redirectSignOut: 'http://localhost:3000',

      responseType: 'code',

      scope: ['profile', 'email', 'openid'],
    },
    region: 'eu-west',
    userPoolId: 'eu-west-2_uvQbrAUmt',
    userPoolWebClientId: 'gvjqjukmm1e2b1mrhpebeumte',
  },
  cognitoMobile: {
    oauth: {
      domain: 'ssg-boilerplate.auth.eu-west-2.amazoncognito.com',
      // optional, for Cognito hosted ui specified options
      options: {
        // Indicates if the data collection is enabled to support Cognito advanced security features. By default, this flag is set to true.
        // AdvancedSecurityDataCollectionFlag: true
      },

      redirectSignIn: 'ssgfeb://',

      redirectSignOut: 'ssgfeb://',

      responseType: 'code',

      scope: ['profile', 'email', 'openid'],
    },
    region: 'eu-west',
    userPoolId: 'eu-west-2_uvQbrAUmt',
    userPoolWebClientId: 'gvjqjukmm1e2b1mrhpebeumte',
  },
  debug: false,
  flagsmith: 'VeDGfkWzr8is5Q3MUD9Aur',
  ga: '',
  google: {
    apiKey: 'AIzaSyCXP6ri2PxJx6H2-k1DRSWH7Yt2Kja3J10',
    clientID:
      '777774295209-0n0n263qpl5d9a17ckg221fl7e4umqq6.apps.googleusercontent.com',
  },
  headers: {
    'Tenant-ID-Authentication': 2,
  },
  logs: {
    API: false,
    COGNITO: false,
    DATA: false,
    DISPATCHER: false,
    EVENTS: true,
    PUSH_NOTIFICATIONS: false,
    SERVER: false,
    STORAGE: false,
    STORE: false,
  },
  loqate: 'PR28-BF96-PB24-GY46',
  oldAdmin: 'https://www2.hoxtonmix.com/',
  sentry:
    'https://7c597040c23bea0b9489521660fc8d98@o31090.ingest.sentry.io/4506693485395968',
  sentryEnvironment: 'production',
  ...projectOverrides,
}

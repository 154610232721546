import classNames from 'classnames'
import { useRouter } from 'next/router'
import { FC, useEffect, useState } from 'react'

import Input from './base/forms/Input'
import { safeParseEventValue } from 'common/utils/forms/safeEventParseValue'
import { getMailQueueItemByBarcodes } from 'common/services/useMailQueueItemByBarcode'
import { getStore } from 'common/store'
import { MailQueueItem as TMailQueueItem } from 'common/types/responses'

type SiteSearchType = {}
function handleKeyPressWhenUnfocused(event) {
  // Check if no element is focused and the key pressed is '/'
  if (
    (document.activeElement === document.body ||
      document.activeElement === null) &&
    event.key === '/'
  ) {
    // Prevent the default action (e.g., typing the character)
    event.preventDefault()
    // Call the function or handle the event here
    console.log("Nothing is focused, '/' key pressed.")
    // Your custom function call
    document.getElementById('site-search')?.focus()
  }
}
if (typeof document !== 'undefined') {
  document.addEventListener('keydown', function (event) {
    // We handle keydown to prevent character typing in inputs/other elements
    if (event.key === '/') {
      handleKeyPressWhenUnfocused(event)
    }
  })
}
const SiteSearch: FC<SiteSearchType> = ({}) => {
  const router = useRouter()
  const [value, setValue] = useState('')

  useEffect(() => {
    if (router.pathname !== '/dashboard/search-result') {
      setValue('')
    }
  }, [router.pathname])

  useEffect(() => {
    if (value.length === 12) {
      getMailQueueItemByBarcodes(getStore(), {
        id: value,
      }).then((res) => {
        if (res.data) {
          const mailQueueItem = res.data as TMailQueueItem
          if (mailQueueItem.mail_id) {
            router.replace(`/mail/${mailQueueItem.mail_id}`)
          } else {
            router.replace(
              `/operations/batches/${mailQueueItem.batches?.[0]}/${mailQueueItem.id}`,
            )
          }
        }
      })
    }
  }, [value])

  const handleSearch = () => {
    router.replace(`/dashboard/search-result?query=${value}`)
  }

  return (
    <div className='position-relative'>
      <Input
        onKeyUp={(e) => {
          if (e.key === 'Enter') {
            handleSearch()
          }
        }}
        value={value}
        onChange={(e) => {
          setValue(safeParseEventValue(e))
        }}
        id='site-search'
        placeholder={'Search or enter a barcode...'}
      />
      <button
        disabled={!value}
        onClick={handleSearch}
        className={classNames('position-absolute btn btn-text border-0', {
          'text-muted': !value,
        })}
        style={{
          right: 0,
          top: 0,
        }}
      >
        <span className='fa fa-search' />
      </button>
    </div>
  )
}

export default SiteSearch
